<!--Chat: (单个)评论 2024/07/20 15:56:15 huangmx-->

<template>
  <div class="chat">
    <HeadCommon :item="item.sender">
      <div class="receive-user" v-if="item.receiveUser && item.receiveUser.id">
        <i class="el-icon-right arrow"></i>
        <span class="user-name">{{ item.receiveUser.name }}</span>
      </div>
    </HeadCommon>
    <div class="chat-content">
      <div class="content-msg">{{ content }}</div>
      <div class="content-img" v-show="item.pictureList && item.pictureList.length > 0">
        <div v-for="(img, index) in item.pictureList" :key="'img_' + index" class="img-item" :style="{ backgroundImage: 'url(' + img + ')' }"></div>
      </div>
      <div class="content-bottom">
        <div class="bottom-left">
          <div class="bottom-left-time">
            <span>{{ new Date(item.gmtCreate).getTime() | formatTimeAgo }}</span>
            <!-- <span class="ml-8">{{ '来自' + item.sender.ipLocation }}</span> -->
          </div>
          <div class="bottom-left-operate" v-if="this.$store.state.user.id === item.sender.id">
            <a @click="update">{{ $t('edit') }}</a>
            <a @click="del">{{ $t('delete') }}</a>
          </div>
          <div class="bottom-left-report" @click="showReport = true">
            <span>{{ $t('report') }}</span>
          </div>
        </div>
        <div class="bottom-right">
          <div class="inter" :class="{ 'bg-red': liked, 'bg-blue': trampled }">
            <div v-if="trampled === false" class="inter-hort" :class="{ 'hort-active': liked }" @click="interact('like')">
              <img class="inter-img" src="../../assets/images/hot.svg" />
              <span>{{ likeTimes > 0 ? likeTimes : $t('like') }}</span>
            </div>
            <span v-if="!liked && !trampled">|</span>
            <div v-if="liked === false" class="inter-water" :class="{ 'water-active': trampled }" @click="interact('trample')">
              <img class="inter-img" src="../../assets/images/water.svg" />
            </div>
          </div>
          <div class="chat" @click="reply">
            <img class="inter-img" src="../../assets/images/msg.svg" />
            <span>{{ replyCount > 0 ? replyCount : $t('reply') }}</span>
          </div>
        </div>
      </div>
      <input-chat v-show="showReply" :item="updateComment" @submit-comment="submitComment"></input-chat>
      <slot></slot>
    </div>

    <!--举报弹窗-->
    <report :visible.sync="showReport" :target-type="'COMMENT'" :target-id="item.id"></report>
  </div>
</template>
<script>
import HeadCommon from './HeadCommon.vue'
import Report from './Report.vue'
import InputChat from './InputChat.vue'
import { interactionApi, commentApi, trackApi } from '@/utils/api'

export default {
  name: 'Chat',
  components: {
    HeadCommon,
    Report,
    InputChat
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      content: '',
      updateComment: {
        content: '',
        pictureList: []
      }, // 修改评论
      status: 'update', // reply: 回复评论，update：修改评论,delted:以删除
      liked: false,
      trampled: false,
      likeTimes: 0,
      trampleTimes: 0,
      replyCount: 89,
      showReply: false,
      showReport: false,
      list: [],
      trampleInteractionRecordId: '',
      likeInteractionRecordId: ''
    }
  },
  watch: {
    item: {
      handler (newVal, oldVal) {
        if (newVal.interaction) {
          this.likeTimes = newVal.interaction.likeNum || 0
          this.trampleTimes = newVal.interaction.trampleNum || 0
          this.trampled = newVal.interaction.trample || false
          this.liked = newVal.interaction.like || false
          this.trampleInteractionRecordId = newVal.interaction.trampleInteractionRecordId || ''
          this.likeInteractionRecordId = newVal.interaction.likeInteractionRecordId || ''
        }

        console.log('watch', newVal, oldVal)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.replyCount = this.item.childNum
    this.content = this.item.content
    this.updateComment = {
      content: this.item.content,
      pictureList: this.item.pictureList
    }
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    checkDel: function () {
      if (this.status === 'delted') {
        this.$message.warning(this.$t('commentIsDeleted'))
        return false
      }
      return true
    },
    interact: function (interType) {
      if (!this.checkLogin()) {
        return
      }
      if (!this.checkDel()) {
        return
      }
      if (interType === 'like') {
        if (this.liked) {
          // 取消点赞
          this.liked = false
          this.likeTimes--
          this.delInteraction(this.likeInteractionRecordId)
        } else {
          // 点火
          this.liked = true
          this.likeTimes++
          this.addInteraction(this.item.id, 'COMMENT', 'LIKE')
          // 点赞评论 埋点
          trackApi.articleTrack('LIKE_COMMENT', null, null, this.item.id)
        }
      } else if (interType === 'trample') {
        if (this.trampled) {
          // 取消踩
          this.trampled = false
          this.trampleTimes--
          this.delInteraction(this.trampleInteractionRecordId)
        } else {
          // 踩
          this.trampled = true
          this.trampleTimes++
          this.addInteraction(this.item.id, 'COMMENT', 'TRAMPLE')
          // 点踩评论 埋点
          trackApi.articleTrack('TRAMPLE_COMMENT', null, null, this.item.id)
        }
      }
    },
    addInteraction: function (targetId, targetType, interactionType) {
      const params = {
        targetId: targetId,
        targetType: targetType,
        interactionType: interactionType
      }
      interactionApi.createInteraction(params).then(resp => {
        console.log('添加互动成功', resp)
        if (interactionType === 'TRAMPLE') {
          this.trampleInteractionRecordId = resp.data
        } else if (interactionType === 'LIKE') {
          this.likeInteractionRecordId = resp.data
        }
      })
    },
    delInteraction: function (interactionId) {
      if (!interactionId) {
        return
      }
      interactionApi.delInteraction(interactionId).then(resp => {
        console.log('删除互动', resp)
      })
    },
    reply: function () {
      if (!this.checkLogin()) {
        return
      }
      if (!this.checkDel()) {
        return
      }
      this.showReply = !this.showReply
      if (this.showReply) {
        this.status = 'reply'
        this.updateComment = {
          content: '',
          pictureList: []
        }
      }
    },
    update: function () {
      if (!this.checkLogin()) {
        return
      }
      if (!this.checkDel()) {
        return
      }
      this.showReply = !this.showReply
      this.status = 'update'
      this.updateComment = {
        content: this.item.content,
        pictureList: this.item.pictureList || []
      }
    },
    del: function () {
      if (!this.checkLogin()) {
        return
      }
      if (!this.checkDel()) {
        return
      }
      // 调用接口删除评论，评论删除后，显示 评论已删除 即可
      commentApi.delArticleComment(this.item.id).then(resp => {
        console.log('删除成功', resp)
        this.content = this.$t('commentIsDeleted')
        this.status = 'delted'
        this.$message.success(this.$t('deletedSuccess'))
        this.$emit('update-reply')
      })
    },
    submitComment: function (comment) {
      if (!this.checkLogin()) {
        return
      }
      if (!this.checkDel()) {
        return
      }
      if (this.status === 'update') {
        // 修改评论
        this.content = comment.content
        this.showReply = false
        commentApi.updateArticleComment(this.item.id, comment.content, comment.imgs).then(resp => {
          this.$message.success(this.$t('commentSuccess'))
          this.showReply = false
        })
        return
      }
      commentApi.replyComment(this.item.id, this.item.groupKey, comment.content, comment.imgs)
        .then(resp => {
          console.log('回复成功', resp)
          this.$message.success(this.$t('commentSuccess'))
          this.replyCount++
          this.showReply = false
          this.$emit('update-reply')
        })
    },
    checkLogin: function () {
      if (!this.$store.state.user || this.$store.state.user.name === '未登录') {
        this.$store.state.loginVisible = true
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
.chat-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 53px;
}

.chat-content .content-msg {
  font-size: 16px;
  color: rgb(50, 50, 50);
  line-height: 24px;
  word-break: break-all;
  word-wrap: break-word;
  margin-bottom: 10px;
  white-space: pre-line;
}

.chat-content .content-img {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.chat-content .content-img .img-item {
  margin-right: 4px;
  width: 128px;
  height: 128px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
}

.content-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(185, 185, 185);
}

.bottom-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-left .bottom-left-report {
  margin-left: 20px;
  cursor: pointer;
}

.content-bottom .bottom-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content-bottom .bottom-right > div {
  border-radius: 18px;
  background-color: rgb(249, 249, 249);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}

.content-bottom .bottom-right .chat {
  width: 86px;
}

.inter-hort {
  width: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin-right: 14px;
  overflow: hidden;
}

.inter-water {
  width: 26px;
  text-align: right;
  overflow: hidden;
}

.inter-img {
  width: 18px;
  height: 18px;
}

.bottom-right .inter {
  margin-right: 20px;
}

.chat > span {
  margin-left: 10px;
}

.receive-user {
  margin-left: 10px;
}

.receive-user .arrow {
  color: #0580ff;
  font-weight: 700;
  font-size: 16px;
}

.receive-user .user-name {
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
}

.inter .hort-active {
  margin-right: 0;
}

.inter .hort-active .inter-img,
.inter .water-active .inter-img {
  translate: -100px;
  filter: drop-shadow(100px 0 0 white);
}

.bg-red {
  background-color: rgb(220, 38, 38) !important;
  color: white;
}

.bg-blue {
  background-color: rgb(59, 130, 246) !important;
  color: white;
}

.ml-8 {
  margin-left: 8px;
}

.bottom-left-operate {
  margin-left: 10px;
}

.bottom-left-operate a {
  cursor: pointer;
  color: rgb(59, 130, 246);
  margin-left: 10px;
}
</style>
