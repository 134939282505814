<!--PostHeader:文章头 2024/07/14 17:50:46 YuAng-->

<template>
  <div class="post-post-header">
    <div class="cover-area" v-if="item.type === 'CONSULTATION'"
      :style="{ 'background-image': 'url(' + item.covers + ')' }"></div>
    <div class="header-area" :class="{ 'header-area-post': item.type === 'POST' }">
      <div>
        <div class="post-title">
          {{ item.title }}
        </div>
        <div class="post-detail-info">
          <div class="time">{{ new Date(item.gmtCreate).getTime() | formatDate }}</div>
          <div class="action">
            <span v-if="item.interaction.likeNum && item.interaction.likeNum > 0">{{ $t('fire') }}</span>
            <span v-if="item.interaction.likeNum && item.interaction.likeNum > 0">
              {{ item.interaction.likeNum || 0 }}
            </span>
            <span v-if="item.interaction.commentNum && item.interaction.commentNum > 0"
              style="margin-left: 32px">{{ $t('reply') }}</span>
            <span v-if="item.interaction.commentNum && item.interaction.commentNum > 0">
              {{ item.interaction.commentNum || 0 }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PostHeader',
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          interaction: {},
          cover: ''
        }
      }
    }
  },
  data () {
    return {
      tip: 'Hi, PostHeader'
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    xxxAction: function (action) {
      console.log(action)
    }
  }
}
</script>

<style scoped>
.post-post-header {
  width: 100%;
  position: relative;
}

.cover-area {
  height: 430px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.header-area {
  width: 100%;
  height: 174px;
  background: linear-gradient(180deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 98.8%, 0.3) 25%,
      hsla(0, 0%, 97.6%, 0.6) 44%,
      hsla(0, 0%, 100%, 0.9) 69%,
      #fff);
  z-index: 10;
  top: 256px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  position: absolute;
}

.header-area>div {
  width: 718px;
  top: 88px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
}

.header-area>div .post-title {
  width: 474px;
  font-weight: 700;
  font-size: 24px;
  height: 60px;
  white-space: normal;
  line-height: 32px;
  overflow: hidden;
  color: rgba(50, 50, 50);
  letter-spacing: 0.6px;
  word-wrap: break-word;
}

.header-area>div .post-detail-info {
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  line-height: 20px;
  color: rgb(0, 0, 0);
}

.post-detail-info .time {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
}

.post-detail-info .action {
  text-align: right;
}

.post-detail-info .action span {
  margin-left: 8px;
}

.header-area-post {
  position: static;
  height: 104px;
}

.header-area-post>div {
  top: 20px;
}
</style>
